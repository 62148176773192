/* Noto Sans, self hosted vs. direct from google fonts */
@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/NotoSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/NotoSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('/fonts/NotoSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: bold;
  font-display: block;
}

/*
  Custom @font-face for Ultramarine (DM Sans + custom glyphs)

  NOTE: Normally, this should be directly imported from @achieve/sunbeam/dist/ultramarine-font.css
  but due to the custom `font-display: block` strategy (vs. swap which is default from Sunbeam) the
  rules need to be modified and the src url also needs custom query parameter for `display`. That
  said, please keep in mind:

  1. These urls need to match the `preload` href urls in _document.js or there will be duplicate
  resources being requested for each mismatched url

  2. If there are changes to Ultramarine (via Sunbeam package update includes new font asset paths
  for example), these would need to be manually updated to stay in sync.
*/

/* bold */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-Bold.woff2?generation=1674758507930224&alt=media&display=block')
      format('woff2'),
    url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-Bold.woff?generation=1674758509185137&alt=media&display=block')
      format('woff');
}

/* bold italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-BoldItalic.woff2?generation=1674758507217189&alt=media&display=block')
      format('woff2'),
    url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-BoldItalic.woff?generation=1674758509264208&alt=media&display=block')
      format('woff');
}

/* italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-Italic.woff2?generation=1674758507172869&alt=media&display=block')
      format('woff2'),
    url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-Italic.woff?generation=1674758507834264&alt=media&display=block')
      format('woff');
}

/* medium */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-Medium.woff2?generation=1674758508450655&alt=media&display=block')
      format('woff2'),
    url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-Medium.woff?generation=1674758507827934&alt=media&display=block')
      format('woff');
}

/* medium italic */
@font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 500;
  font-display: block;
  src: url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-MediumItalic.woff2?generation=1674758508455312&alt=media&display=block')
      format('woff2'),
    url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-MediumItalic.woff?generation=1674758507196774&alt=media&display=block')
      format('woff');
}

/* regular */
@font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-Regular.woff2?generation=1674758508569885&alt=media&display=block')
      format('woff2'),
    url('https://storage.googleapis.com/download/storage/v1/b/acx-assets-prd-5eeeab5c/o/fonts%2FUltramarine_WOFF%2FUltramarine-Regular.woff?generation=1674758509106386&alt=media&display=block')
      format('woff');
}
